/* eslint-disable import/no-anonymous-default-export */
import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Index_tw = lazy(() => import('./pages/zhTW/zhTW.js'))
const Index_en = lazy(() => import('./pages/enUS/enUS.js'))
const Index_ko = lazy(() => import('./pages/koKR/koKR.js'))
const xym_Usdt = lazy(() => import('./pages/xym-on-usdt'))
export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index}/>
        <Route exact path="/zh-TW" component={Index_tw}/>
        <Route exact path="/en-US" component={Index_en}/>
        <Route exact path="/ko-KR" component={Index_ko}/>
        <Route exact path="/xym-on-usdt" component={xym_Usdt}/>
      </Switch>
    </BrowserRouter>
  );
};
